/**This file contains a constant object that has properties that are necessary
 * for the build found in the `dev` configuration in `angular.json`.
 */

export const environment = {
	production: true,
	baseUrl: 'https://slpsp-service-reparenting-z2ytgelsrq-uk.a.run.app/slpsp-api/',
	openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2',
	openidClientId: 'urn:slpsp:clientid:web_slpspqa:qa',
	openidResource: 'urn:slpsp:resource:web_slpspqa:qa',
	openidRedirectUrl: 'https://qa.slpsp.ford.com',
	currentPage: '',
	accessmanagement:
		'https://slpsp-service-accessmgmt-z2ytgelsrq-uk.a.run.app',
};
